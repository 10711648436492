html, body {
  background: #F3F5F8;
  height: 100%;
  width: 100%;
}
.system-layout {
    width: 100%;
    min-width: 1000px;
    height: 100vh;
    min-height: 400px;
}
.system-layout .system-sidebar-menu {
    border-inline-end: none !important;
}
.system-layout .system-layout-slider {
  height: 100%;
  background: #fff !important;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-right: 1px solid rgba(0,0,0,0.06);
}
.system-layout .header-content {
    border-bottom: 1px solid rgba(0,0,0,0.06);
    display: flex;
}
.system-layout .header-content .header-button-list {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.system-layout .header-content .header-button-list .header-button {
    height: 60px;
}
.system-layout .header-content .header-button-list .header-button:hover {
    background: #fff !important;
}
.system-layout .header-content .title {
    font-size: 1.3em;
    color: #2A61FF;
}
.system-layout .system-layout-content {
    margin: 1.5em 1em;
    background: white;
    border-radius: 4px;
    overflow-y: auto;
    padding: 1.5em 1em;
}

.system-layout .btn{
    box-shadow: none !important;
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 1em;
    border-radius: 4px;
    line-height: 1;
    padding: 4px 17px;
  }

.system-layout .btn-outline-secondary {
    border-color: #D9D9D9;
}

.system-layout .btn-outline-secondary:hover {
border-color: #4978FF;
background: #fff;
color: #2A61FF;
}